<template>
  <v-row>
    <v-col cols="6" v-for="index in amount" :key="index">
      <v-row no-gutters align="center">
        <v-col cols="auto">
          <v-sheet height="100px" width="100px" max-height="100px">
            <VSkeletonLoader type="image" height="100px"/>
          </v-sheet>
        </v-col>
        <v-col class="px-2">
          <VSkeletonLoader type="heading"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProductVariationsSkeletonLoading',
  props: {
    amount: {
      type: Number,
      default: 4,
    },
  },
};
</script>
