<template>
  <v-row class="my-4 mx-0">
    <v-col cols="10" md="8" offset-md="2" offset="1">
      <v-row>
        <v-col cols="12" sm="4">
          <ImageGallery :product="product" v-if="!loading" />
          <VSkeletonLoader type="image" v-else />
        </v-col>
        <v-col cols="12" sm="8">
          <v-row no-gutters>
            <v-col>
              <div class="text-body-2 strike-through" v-if="product.alternativePrice">
                {{ product.price | currency }}
              </div>
              <VSkeletonLoader v-if="loading" type="heading" height="40" />
                <div class="text-h4" v-if="product.alternativePrice">
                  {{ product.alternativePrice | currency }}
                </div>
                <div class="text-h4" v-else>{{ product.price | currency }}</div>
              <div class="text-body-2">Excl BTW</div>
            </v-col>
            <v-col>
              <VSkeletonLoader v-if="loading" type="text" />
              <div class="text-body-2 text-right" v-else>Artikel nummer: {{ product.code }}</div>
              <div class="text-body-2 text-right">
                <v-icon class="mr-2" :size="20">far fa-truck</v-icon>
                Levertijd: 2-3 dagen
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-3">
            <v-col cols="12" md="4">
              <affirmative-button color="primary" :disabled="loading" :loading="addingProduct" @click="handleAddOrder"
                message="Product is toegevoegd aan uw winkelwagen">
                <v-icon class="mr-3">far fa-shopping-cart</v-icon>
                Voeg toe
              </affirmative-button>
            </v-col>
          </v-row>
          <VDivider class="my-3" />

          <v-row class="text-h6 ma-0">
            Productnaam
          </v-row>
          <VSkeletonLoader v-if="loading" type="text@1" />
          <v-row class="text-body-1 ma-0 pt-3" v-html="product.name" v-else>
          </v-row>

          <VDivider class="my-3" />

          <v-row class="text-h6 ma-0">
            Beschrijving
          </v-row>
          <VSkeletonLoader v-if="loading" type="text@3" />
          <v-row class="text-body-1 ma-0 pt-3" v-html="product.description" v-else-if="product.description">
          </v-row>
          <v-row class="text-body-1 ma-0 pt-3" v-else>
            <span class="grey--text">Geen omschrijving beschikbaar</span>
          </v-row>

          <VDivider class="my-3" />

          <v-row class="text-h6 ma-0">
            Specificaties
          </v-row>
          <v-row class="text-body-1 ma-0 pt-3">
            <VSkeletonLoader v-if="loading" type="table-row@2" />
            <v-simple-table v-else-if="implementation">
              <template v-slot:default>
                <tbody>
                  <tr v-for="spec in specifications" :key="spec.label">
                    <td>{{ spec.label }}</td>
                    <td>{{ spec.value || '-' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>

          <VDivider class="my-3" />

          <v-row class="text-h6" no-gutters>
            Andere varianten van dit product
          </v-row>
          <ProductVariations :implementations="implementations.slice(0, 4)"
            v-if="implementations && !loading && !loadingImplementations" />
          <ProductVariationsSkeletonLoading v-else-if="loading || loadingImplementations" />

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ImageGallery from '../components/ImageGallery.vue';
import ProductVariations from '../components/Products/ProductVariations.vue';
import { fetch, getImplementations } from '../api/product/product.js';
import { Product } from '@/application/models/Product.js';
import { mapMutations } from 'vuex';
import AffirmativeButton from '@/components/AffirmativeButton.vue';
import ProductVariationsSkeletonLoading from '@/components/Products/ProductVariationsSkeletonLoading.vue';

export default {
  name: 'Product',
  components: {
    ProductVariationsSkeletonLoading,
    AffirmativeButton,
    ProductVariations,
    ImageGallery,
  },
  data: () => ({
    product: {},
    addingProduct: false,
    implementations: [],
    loading: false,
    loadingImplementations: false,
  }),
  computed: {
    implementation() {
      return this.product?.implementation ? this.product.implementation : null;
    },
    specifications() {
      return [
        {
          label: 'Merk',
          value: this.product?.implementation?.brand?.name,
        },
        {
          label: 'Model',
          value: this.product?.implementation?.model?.name,
        },
        {
          label: 'Uitvoering',
          value: this.product?.implementation?.name,
        },
        {
          label: 'Groep',
          value: this.product?.productGroup?.name,
        },
        {
          label: 'Categorie',
          value: this.product?.material?.name,
        },
      ]
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        if (this.$route?.params?.code !== this.product?.code) {
          await this.getProduct();
          this.setHero();
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$hero.$emit('setTitle', '');
    await this.getProduct();
    this.setHero()
  },
  methods: {
    ...mapMutations('order', ['addOrderLine']),
    setHero() {
      this.$hero.$emit('setTitle', this.product?.name || '');
      this.$hero.$emit('setCanSearch', true);
      this.$hero.$emit('setBreadcrumbs', [
        {
          text: 'home',
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'assortiment',
          to: { name: 'order' },
        },
        {
          text: 'uw keuze',
          to: { name: 'products' },
        },
        {
          text: this.product?.code || '',
          to: { name: 'product' },
          disabled: true,
        },
      ]);
    },
    async getProduct() {
      this.loading = true;
      this.loadingImplementations = true;
      const { data } = await fetch(this.$route.params.code);

      if (!data?.length) {
        this.$router.push({
          name: '404',
        });
        return;
      }

      this.product = new Product().mapResponse(data[0]);

      this.loading = false;

      await this.getImplementations();
    },
    handleAddOrder() {
      this.addingProduct = true;

      this.addOrderLine(this.product);

      this.$nextTick(() => this.addingProduct = false);
    },
    async getImplementations() {
      const { data } = await getImplementations(this.product?.id);
      this.implementations = data;
      this.loadingImplementations = false;
    },
  },
};
</script>
