<template>
  <v-row>
    <v-col cols="6" v-for="variation in variations" :key="variation.id" class="cursor-pointer">
      <v-menu>
        <template #activator="{ on }">
          <v-row no-gutters align="center" class="ma-0" v-on="on">
            <v-col cols="3">
              <VImg :src="variation.imageUrl"
                    contain
                    width="100%"
                    aspect-ratio="1" />
            </v-col>
            <v-col cols="9" class="pl-3">
              <span>{{ variation.name }}</span>
            </v-col>
          </v-row>
        </template>
        <v-card width="400px">
          <v-card-text>
            <VImg :src="variation.imageUrl"/>
            <div class="text-h6">{{ variation.name }}</div>
            <div class="text-body-2">Artikel nummer: {{ variation.code }}</div>
            <div class="text-body-2">Levertijd: 2-3 dagen</div>
          </v-card-text>
          <v-card-actions>
            <VSpacer/>
            <v-btn class="primary" @click="openProduct(variation)">Bekijk product</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { getPlaceholder } from '@/application/util/imagePlaceholder.js';

export default {
  name: 'ProductVariations',
  props: {
    implementations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    variations() {
      return this.implementations.map(implementation => {
        return {
          id: implementation.id,
          name: implementation.name,
          code: implementation.code,
          imageUrl: implementation?.mainImage?.url ?? getPlaceholder(),
        };
      });
    },
  },
  methods: {
    openProduct(product) {
      this.$router.push({
        name: 'product',
        params: { code: product?.code },
      });
    },
  },
};
</script>

<style scoped>

</style>
