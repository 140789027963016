<template>
  <v-container class="pt-0">
    <v-row no-gutters>
      <VImg :src="heroImage"
            contain
            width="100%"
            class="mb-1"
            aspect-ratio="1"
      />
    </v-row>
    <v-row no-gutters v-if="this.product.images.length">
      <v-col sm="3" class="pa-1" v-for="(image, key) in images" :key="key">
        <VImg
          contain
          @click="heroImage = image"
          :src="image"
          aspect-ratio="1"
          class="cursor-pointer"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getPlaceholder } from '@/application/util/imagePlaceholder.js';

export default {
  name: 'ImageGallery',
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    images() {
      return [this.product.mainImage?.url || getPlaceholder(), ...this.product.images.map(image => image.url)]
    },
  },
  data() {
    return {
      heroImage: this.product.mainImage?.url || getPlaceholder(),
    };
  }
};
</script>
